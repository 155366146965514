@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');

*p {
  font-family: arial black, arial-w01-black, arial-w02-black, arial-w10 black,
    sans-serif;
}

.cardWrapper {
  padding: 1.3rem;
  border-radius: 0;
  position: relative;
  overflow: hidden;
  background: url("../images/header-background.jpg");
  background-size: cover;
}

.innerCardWrapper {
  text-align: center;
}

.cardBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: transparent;
  backdrop-filter: blur(1.92px);
}

.logoIcon {
  width: 190px;
  height: 190px;
  border-radius: 50%;
  margin: 1rem 0;
  position: relative;
  z-index: 2;
  left: -37px;
  top: -31px;
  box-shadow: black 0px 0px 6px;
}

.subTextWrapper,
.subTextWrapperComingSoon {
  background-color: #0000009d;
  padding: .5rem;
  border-radius: .5rem;
  position: relative;
  top: -1rem;
  width: 79vw;
}

.subTextWrapperComingSoon {
  top: 1rem;
}

.subText,
.subTextVideo,
.subTextOffer,
.subTextAccept,
.subTextCall,
.subTextEm,
.introText {
  font-size: 1rem;
  line-height: 1rem;
  color: white;
  position: relative;
  text-transform: uppercase;
  margin: .2rem 0;
}

.introText {
  text-transform: none;
  padding: .5rem .5rem 1rem .5rem;
  line-height: 1.2rem;
}

.subTextVideo {
  margin-bottom: 1rem;
}

.subTextOffer {
  margin-top: 1rem;
}

.subTextAccept,
.subTextPayments {
  text-transform: capitalize;
}

.subTextCall,
.subTextEm {
  text-transform: none;
  color: #bf7e3b;
}

.subTextEm {
  font-size: .98rem;
}

a {
  text-decoration: none;
  color: #e1872d;
}

.btnWrapper {
  position: relative;
  left: -28px;
  margin-top: 11px;
}

.btnPhotos,
.btnMusic,
.btnPrints,
.btnBookNow,
.btnPricePackages {
  background-color: #010909d4;
  border: 1px solid #000000;
  position: relative;
  z-index: 4;
  text-transform: uppercase;
  width: 7rem;
  margin-top: .2rem;
  rotate: 337deg;
  top: -47px;
}

.btnPhotos {
  rotate: 337deg;
  top: 6px;
  left: -88px;
  width: 6rem;
}

.btnMusic {
  rotate: 337deg;
  top: -72px;
  left: 3px;
  width: 6rem;
}

.btnPrints {
  rotate: 337deg;
  top: -153px;
  left: 102px;
}

.btnBookNow,
.btnPricePackages {
  rotate: 0deg;
  top: 0;
  background-color: #bf7e3b;
  border: 1px solid #bf7e3b;
  width: 14rem;
}

.btnPricePackages{
  padding: 0.375rem 0.75rem;
  border-radius: 0.375rem;
  vertical-align: middle;
  display: inline-block;
  background-color: #985f26;
  color: white;
  
}

.btnPhotos:hover,
.btnMusic:hover,
.btnPrints:hover {
  background-color: #010909;
  border: 1px outset white;
  color: #bf7e3b;
}

.btnBookNow:hover,
.btnPricePackages:hover {
  rotate: 0deg;
  top: 0;
  background-color: #dcaa78;
  border: 1px outset white;
  color: white;
}

.btnDivWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.diagonalOne,
.diagonalTwo,
.diagonalThree,
.diagonalFour {
  width: 100rem;
  position: relative;
}

.diagonalOne:after,
.diagonalTwo:after,
.diagonalThree:after,
.diagonalFour:after {
  content: "";
  position: absolute;
  width: 101rem;
  transform: rotate(157deg);
  overflow: auto;
}

.diagonalOne:after {
  border-top: 124px solid #01295e91;
  transform-origin: 8% -98%;

}

.diagonalTwo:after {
  border-top: 107px solid #ca590e8e;
  transform-origin: 32% -135%;
}

.diagonalThree:after {
  border-top: 107px solid #ffffff8a;
  transform-origin: 42% -113%;
}

.diagonalFour:after {
  border-top: 50px solid #0000009d;
  transform-origin: 42% -187%;
}

.emile,
.emileComingSoon {
  color: white;
  font-family: "Rock Salt";
  transform: rotate(338deg);
  font-size: 1.4rem;
  position: relative;
  top: -79px;
  width: 20rem;
  text-shadow: #010909 1px 1px 5px;
}

.emileComingSoon {
  top: -50px;
  color: black;
  text-shadow: none;
  font-weight: 600;
}

@media screen and (min-width:600px) {
  .diagonalThree:after {
    transform-origin: 39% -104%;
  }

  .logoIcon {
    width: 205px;
    height: 205px;
    left: -146px;
    top: -5px;
  }

  .btnPhotos {
    top: 30px;
    left: -145px;
    width: 9rem;
  }

  .btnMusic {
    top: -67px;
    left: -9px;
    width: 9rem;
  }

  .btnPrints {
    top: -164px;
    left: 126px;
    width: 9rem;
  }

  .emile {
    font-size: 2rem;
    width: 34rem;
  }

  .subTextWrapper {
    top: 0;
    padding: 1rem 2rem;
  }

}

@media screen and (min-width:768px) {
  .diagonalThree:after {
    transform-origin: 37% -98%;
  }

  .subTextWrapper {
    top: 17px;
  }
}

@media screen and (min-width:1024px) {
  .diagonalThree:after {
    transform-origin: 38% -98%;
  }

  .diagonalTwo:after {
    transform-origin: 39% -155%;
  }

  .diagonalOne:after {
    transform-origin: 41% -185%;
  }

  .subTextWrapper {
    top: 22px;
  }

  .diagonalFour:after {
    transform-origin: 42% -180%;
  }
}