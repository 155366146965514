.cardWrapper {
    padding: 1.3rem 1.3rem 3rem 1.3rem;
    border-radius: 0;
    background: url("../images/header-background.jpg");
    background-size: cover;
    display: flex;
    justify-content: center;
    height: 100vh;
  }
  
  .innerCardWrapper {
    text-align: center;
    width: 100rem;
  }
  
  .cardBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem 1.5rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.5206874986322654) 125%,
      rgba(2, 0, 36, 0) 100%
    );
    box-shadow: 0 1px 5px rgb(0 0 0 / 83%);
    backdrop-filter: blur(1.52px);
  }