@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');

.cardBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 2rem .5rem 2rem;
}

.cardWrapper {
  padding: 1.3rem;
  border-radius: 0;
  background: url("../images/clothing-background.jpg") no-repeat center top;
  background-size: cover;
}

.outerWrapper {
  background: linear-gradient(173deg, rgb(1 42 94) 0%, rgba(255, 255, 255, -89.479313) 101%, rgba(36, 84, 81, 101) 59%);
}

.innerCardWrapper {
  text-align: center;
  background: url("../images/flyer-port.png") no-repeat center top 117px;
  background-size: contain;
  display: block;
  /* width: 100vw; */
  height: 112vw;
}

.animation {
  background: url("../images/flyer-port.gif") no-repeat;
  width: 100vw;
  height: 43vh;
  background-size: contain;
  margin: 1.2rem 0 0 .2rem;
}

.clothesTitle {
  font-size: 2rem;
  font-weight: bold;
  color: #ededed;
  font-family: "Rock Salt";
}

@media screen and (min-width: 375px) {
  .clothesTitle {
    font-size: 1.8rem;
  }

  .innerCardWrapper {
    background: url("../images/flyer-port.png") no-repeat center top 107px;
    background-size: contain;
    height: 106vw;
  }

  .animation {
    margin-top: -0.4rem;
  }
}

@media screen and (min-width: 414px) {

  .innerCardWrapper {
    height: 105vw;
  }
}

@media screen and (min-width: 600px) {
  .innerCardWrapper {
    background: url("../images/flyer-port.png") no-repeat center top 135px;
    background-size: 115% 85%;
  }

  .animation {
    margin: 3.6rem 0 0 3.8rem;
    height: 80vw;
  }

  .clothesTitle {
    font-size: 2rem;
    margin-top: 1rem;
  }

  .h5Style {
    font-size: 1rem;
    font-weight: 700;
  }
}

@media screen and (min-width: 768px) {
  .animation {
    margin-top: 4.6rem;
  }
}

@media screen and (min-width: 1024px) {
  .innerCardWrapper {
    background: url("../images/flyer-landscape.png") no-repeat center top 171px;
    background-size: contain;
    height: 57vh;
  }

  .clothesTitle {
    font-size: 2rem;
  }

  .animation {
    height: 51vh;
    margin: -1.4rem 0 0 7.8rem;
  }
}

@media screen and (min-width: 1200px) {
  .innerCardWrapper {
    background: url("../images/flyer-landscape.png") no-repeat center top 166px;
    background-size: contain;
    height: 72vh;
  }

  .animation {
    height: 61vh;
    margin: -2.4rem 0 0 7.8rem;
  }
}

@media screen and (min-width: 1300px) {
  .animation {
    margin: -2.4rem 0 0 14.8rem;
  }
}

@media screen and (min-width: 1500px) {
  .animation {
    height: 68vh;
    margin: -2.4rem 0 0 19.8rem;
  }
}

@media screen and (min-width: 1640px) {
  .animation {
    height: 64vh;
    margin: -3.4rem 0 0 28.8rem;
  }
}

@media screen and (min-width: 1780px) {
  .animation {
    height: 64vh;
    margin: -3.4rem 0 0 36.8rem;
  }
}


@media screen and (min-width: 1900px) {
  .animation {
    height: 64vh;
    margin: -3.4rem 0 0 46.8rem;
  }
}

@media screen and (min-width: 2067px) {
  .innerCardWrapper {
    height: 80vh
  }

  .animation {
    display: none;
  }
}