@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');

.profileWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.profileTitle {
    font-size: 1.8rem;
    text-align: left;
    color: white;
    padding: 1rem 0;
    font-family: "Rock Salt";
}

.profileContent,
.profileExpandedContent {
    padding: .8rem;
    background-color: rgba(0, 0, 0, 0.274);
    border-radius: .5rem;
    color: white;
}

.screen,
.screenBottom {
    background: url("../../images/emile/320-verticle-profile.jpg") no-repeat center top 222px;
    background-size: contain;
    display: block;
    margin-top: -14rem;
    width: 100%;
    height: 570px;
}

.screenBottom {
    background: url("../../images/emile/320-verticle-bottom-profile.jpg") no-repeat center bottom 1px;
    background-size: 100% 101%;
    width: 100%;
    height: 222px;
    margin: 0;
}

.readMoreBtn {
    background-color: black;
    border: 1px solid white;
    border-radius: .3rem;
    color: wheat;
    padding: 0.1rem 0.5rem;
}

.readMoreBtn:hover {
    background-color: rgb(255, 255, 255);
    border: 1px solid white;
    border-radius: .3rem;
    color: rgb(0, 0, 0);
}

.profileExpandedContent {
    margin-top: -5px;
    padding: 0 .5rem .5rem .8rem;
}

.riseWrapper {
    padding: 1rem;
}

.hrStyle {
    display: block;
    height: 1px;
    border: 0;
    border-top: 5px solid #ccc;
    margin: 0 0 1rem 0;
    padding: 0;
}

.accordionBackground {
    background: url("../../images/emile/background.jpg") no-repeat;
    background-size: cover;
}

.emStyle {
    font-size: 1.2rem;
    line-height: 1.2rem;
    margin-top: 2rem;
    color: #bf7e3b;
}

.h2 {
    color: #797777;
    margin: 1rem 0 0 0;
}

@media screen and (min-width:375px) {
    .screen {
        background-repeat: repeat;
        background-position: center top 188px;
        background-size: 100% 110%;
        margin-top: -12rem;
    }

    .screenBottom {
        background-repeat: repeat;
        background-position: center bottom 1px;
        background-size: 91% 100%;
    }
}

@media screen and (min-width:414px) {
    .screen {
        background-position: center top 108px;
        background-size: 100%;
        margin-top: -7rem;
        height: 565px;
    }

    .screenBottom {
        background-repeat: repeat;
        background-position: center bottom -22px;
        background-size: 91% 114%;
    }
}

@media screen and (min-width:568px) {
    .screen {
        background: url("../../images/emile/812-wide-profile.jpg") center top 108px;
        height: 482px;
    }

    .screenBottom {
        display: none;
    }
}

@media screen and (min-width:600px) {
    .screen {
        background: url("../../images/emile/600-verticle-profile.jpg") center top 106px;
        background-size: 100% 129%;
        margin-top: -7rem;
        height: 640px;
    }

    .screenBottom {
        background: url("../../images/emile/600-verticle-bottom-profile.jpg") center bottom 1px;
        background-size: 100% 100%;
    }

    .profileContent {
        padding: 1.8rem 1.5rem 1.8rem 1.5rem;
    }

    .profileExpandedContent {
        margin-top: -1.5rem;
        padding: 0 1.5rem 1.8rem 1.5rem;
    }

    .iFrame {
        width: 100%;
        height: 34rem;
    }
}

@media screen and (min-width:667px) {
    .screen {
        background: url("../../images/emile/812-wide-profile.jpg") center top 108px;
        height: 482px;
    }
}


@media screen and (min-width:768px) {
    .screen {
        background: url("../../images/emile/768-verticle-profile.jpg") center top 108px;
        background-size: 100% 122%;
        height: 706px;
    }

    .screenBottom {
        background: url("../../images/emile/768-verticle-bottom-profile.jpg") center;
        background-size: 100% 106%;
        display: block;
    }
}

@media screen and (min-width:1024px) {
    .screen {
        background: url("../../images/emile/1024-wide-profile.jpg") center top 109px;
        background-size: 100% 115%;
        height: 718px;
    }

    .screenBottom {
        background: url("../../images/emile/1024-wide-bottom-profile.jpg") center;
        background-size: 80% 102%;
        display: block;
    }

    .iFrame {
        height: 44rem;
    }
}

@media screen and (min-width:1366px) {
    .screen {
        background: url("../../images/emile/1024-wide-profile.jpg") center top 109px;
        background-size: 80% 115%;
        height: 718px;
    }

    .iFrame {
        width: 86%;
    }

    .screenBottom {
        background: url("../../images/emile/1024-wide-bottom-profile.jpg") center;
        background-size: 70% 102%;
        display: block;
    }
}

@media screen and (min-width:1920px) {
    .screen {
        background: url("../../images/emile/1024-wide-profile.jpg") center top 109px;
        background-size: 60% 98%;
        height: 718px;
    }

    .screenBottom {
        background: url("../../images/emile/1024-wide-bottom-profile.jpg") center;
        background-size: 46% 102%;
        display: block;
    }
}