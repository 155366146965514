@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');

.cardBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
}

.cardWrapper {
  padding: 2rem 1.3rem;
  border-radius: 0;
}

.innerCardWrapper {
  text-align: center;
}

.musicTitle {
  font-family: "Rock Salt";
  font-size: 2rem;
  font-weight: bold;
  color: #d7bb9e;
  margin-top: 1rem;
}

.musicBtn:hover {
  color: #e6bf98;
}

.purchaseTitleBtn{
  color: white;
  font-size: 1rem;
  padding: .5rem;
  display: block;
  background-color: #bf7e3b;
  border: 1px solid #bf7e3b;
  /* border-radius: .5rem; */
  /* width: 14rem; */
  color: white;
  padding: .4rem .5rem;
  text-transform:uppercase;
  margin-top: -.3rem;
}

.musicPanel {
  padding: .3rem 0;
  border-bottom: 1px solid wheat;
  background: #bf7e3b3d;
}

.musicPanel:hover {
  background: #68431d8f;
}

.purchaseTitleBtn:hover{
  background-color: #dcaa78;
  border: 1px outset white;
  color:white;
}

.playerWrapper {
  width: 100%;
  padding: 1rem 0;
}

.riseTitle,
.freshTitle,
.watchTitle {
  color: white;
  margin: 0;
  text-align: left;
  font-family: "Rock Salt";
  font-size: 1rem;
  padding: 0 0 .7rem .2rem;
}

@media screen and (min-width:375px) {
  .purchaseTitleBtn {
    padding: .5rem 2.5rem;
    display: block;
    width: 100%;
  }
}

@media screen and (min-width:414px) {
  /* .purchaseTitleBtn {
    padding: .5rem 3.5rem;
  } */
}

@media screen and (min-width:600px) {
  /* .purchaseTitleBtn {
    width: 80vw;
  } */
}

@media screen and (min-width:768px) {
  /* .purchaseTitleBtn {
    width: 80.7vw;
  } */
}

@media screen and (min-width:1024px) {
  .cardBody {
    width: 40rem;
  }

  .cardWrapper {
    display: flex;
    justify-content: center;
    padding: 4rem 3.3rem 3.3rem 3.3rem;
  }
  /* .purchaseTitleBtn {
    width: 56.7vw;
  } */
}

@media screen and (min-width:1200px) {
  .cardBody {
    width: 58rem;
  }
  /* .purchaseTitleBtn {
    max-width: 680.6px;
  } */
}