@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');

.email,
.name,
.address,
.address2,
.city,
.state,
.zip,
.textarea {
  padding: 0 0.3rem;
  margin: 0.3rem 0;
}

.error {
  fill: #ffd23b;
  width: 24px;
  height: 21px;
}

.namePhoto,
.name,
.emailPhoto,
.email,
.phoneNumberPhoto,
.phone,
.timePhoto,
.datePickerPhoto,
.typePhoto {
  height: 2rem;
  margin: -.5rem 0 .4rem 0;
  background-color: rgba(255, 255, 255, 0.66);
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-radius: 0% !important;
}

.datePickerPhoto {
  border-bottom: 1px rgba(0, 0, 0, 0.137) solid !important;
  width: 87vw !important;
}

.datePickerPhotoError {
  border-bottom: 1px rgba(255, 0, 0, 0.137) solid !important;
  width: 87vw !important;
}

.detailsPhoto,
.explainPhoto {
  height: 10vh;
  margin: -.4rem 0 1rem 0 !important;
}

.contact {
  font-size: 2rem;
  font-weight: bold;
  color: #bf7e3b;
  font-family: "Rock Salt";
  text-align: center;
  margin-bottom: 1rem;
}

.innerCardWrapper {
  padding: 1rem;
  background: url("../images/city.png") no-repeat center bottom;
  background-size: 100%;
  background-color: #fff1e5;
  text-align: left;
}

.optionsDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: rgba(255, 255, 255, 0.475);
  padding: .5rem;
  border-radius: .3rem;
}

.interestedInTitle {
  text-align: left;
  font-size: .96rem;
  color: #162c52;
  line-height: 1.2rem;
}

.generalInputForm {
  background-color: #1250a11d;
}

.ClothesInputForm {
  background-color: rgba(135, 133, 133, 0.225);
}

.MusicInputForm {
  background-color: rgba(234, 194, 194, 0.225);
}

.btn,
.btnContact,
.btnContactGoBack {
  border: 1px solid #000000;
  text-transform: uppercase;
  margin-top: .5rem;
  background-color: #bf7e3b !important;
  border: 1px solid #835424 !important;
  width: 100%;
}

.btnContactGoBack {
  width: 5rem;
  padding:.1rem;
  background-color: #865727 !important;
}

.btn:hover, 
.btnContact:hover,
.btnContactGoBack:hover {
  rotate: 0deg;
  top: 0;
  background-color: #dcaa78 !important;
  border: 1px outset white;
}

.subTitle {
  font-weight: 700;
}

.subText {
  font-size: 1rem;
}

.h5Style {
  font-size: 1rem;
  font-weight: 700;
}

.footer {
  font-size: .85rem;
  text-align: center;
  color: white;
  background-color: #162c52;
  padding: .5rem 0;
}

@media screen and (min-width: 422px) {
  .innerCardWrapper {
    background: url("../images/city.png") no-repeat center bottom;
    background-size: cover;
    background-color: #fff1e5;
  }
}

@media screen and (min-width: 600px) {
  .innerCardWrapper {
    background: url("../images/city.png") no-repeat center bottom -77px;
    background-size: cover;
    background-color: #fff1e5;
    text-align: left;
  }

  .interestedInTitle {
    font-size: 1.2rem;
    line-height: 1.3rem;
  }

  .btn {
    width: 14rem;
  }

  .datePickerPhoto {
    width: 92vw !important;
  }

  .subTitle {
    font-size: 2rem;
  }

  .subText{
    font-size: 1rem;
  }

}

@media screen and (min-width: 768px) {
  .innerCardWrapper {
    background: url("../images/city.png") no-repeat center bottom -183px;
    background-size: cover;
    background-color: #fff1e5;
  }

  .interestedInTitle {
    font-size: 1.5rem;
    line-height: 1.6rem;
  }

  .datePickerPhoto {
    width: 95.9vw !important;
  }
}

@media screen and (min-width: 1024px) {
  .innerCardWrapper {
    background: url("../images/city.png") no-repeat center bottom -320px;
    background-size: cover;
    background-color: #fff1e5;
  }

  .optionsDiv {
    flex-direction: row;
  }

  .datePickerPhoto {
    width: 97vw !important;
  }
}

@media screen and (min-width: 1200px) {
  .optionsDiv {
    flex-direction: row;
  }

  .interestedInTitle {
    width: 100%;
  }
}