@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');

.cardBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
}

.cardWrapper {
  padding: 1.3rem;
  border-radius: 0;
  /* background: linear-gradient(153deg,
      rgb(224 110 45) 9%,
      rgb(191 126 59) 32%,
      rgba(2, 0, 36, 0) 75%); */
      background: url("../images/grafetti-background.png");
      background-size: cover;
}

.innerCardWrapper {
  text-align: center;
}

.aboutEmileSm,
.aboutEmileLg {
  font-family: "Rock Salt";
  font-size: 2rem !important;
  font-weight: bold;
  color: white;
  text-shadow: #48240a 1px 1px 5px;
}

.aboutEmileLg {
display: none;
}

.emileImg {
  width: 20rem
}

.subText {
  font-size: 1rem;
  line-height: 1.3rem;
  color: #ffffff;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subLinkClass, 
.subLInkInquire {
  display: block;
  background-color: #bf7e3b;
  border: 1px solid #bf7e3b;
  border-radius: .5rem;
  width: 14rem;
  color: white;
  padding:.4rem .5rem;
}

.subLInkInquire {
  background-color: #458a47;
  border: 1px solid #458a47;
  width: 7rem;
  margin-left: .5rem;

}

.subLinkClass:hover,
.subLInkInquire:hover {
  background-color: #dcaa78;
  border: 1px outset white;
  color:white;
}

.subLInkInquire:hover {
  background-color: #73bd76;
  border: 1px outset #458a47;
  color:white;
}

@media screen and (min-width: 1024px) {
  .cardBody {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding:1rem;
  }

  .aboutEmileSm {
    display: none;
  }

  .aboutEmileLg {
    display: block;
    padding-bottom: 1rem;
  }

  .subText {
    width: 28rem;
    padding:1rem
  }
}

@media screen and (min-width: 1920px) {
  .aboutEmileLg {
font-size: 3rem !important;
padding-bottom: 2rem;
  }
  .emileImg {
    width: 30rem
  }
}