.nav-link {
    color: white !important
}

.navbar-toggler {
    background-color: #8f9ba2;
}

.card {
    --bs-card-bg: #202124;
}

.accordion-button {
    background: url("../images/concert/gallery-cover.png") center no-repeat;
    background-size: 100% 100%;
    padding: 4rem;
    background-color: #202124;
    height: 29vh;
    padding: 0 4.5rem 10rem 4.5rem;
    color: white;
}

.accordion-button::after {
    margin-top: 0;
    background-image: none;
    width: 1rem;
    height: 1rem;
}

.accordion-button:not(.collapsed)::after {
    background-image: none;
}


.accordion {
    --bs-accordion-bg: #000;
    --bs-accordion-active-bg: #000000;
}

.accordion img {
    display: none;
}

/* music player */
.rise {
    background: url("../images/music/rise.png") no-repeat center bottom !important;
    background-size: 100%!important;
    border: 3px outset rgba(255, 255, 255, 0.44);
}

.fresh-air {
    background: url("../images/music/fresh-air.png") no-repeat center bottom  !important;
    background-size: 100% !important;
    border: 3px outset rgba(255, 255, 255, 0.44);
}

.watch {
    background: url("../images/music/watch.png") no-repeat center top  !important;
    background-size: 100% !important;
    border: 3px outset rgba(255, 255, 255, 0.44);
}

.rhap_time {
    color: white !important;
}

.rhap_container svg {
    color: #cacacade;
}

.Music_cardBody__JCoib {
    padding: .5rem !important;
    background-color: #000000c4 !important;
    backdrop-filter: blur(1.52px);
    border-radius: .5rem;

}

.Music_cardWrapper__rC8is {
    background: url("../images/music/music-background.png");
    background-size: 100%  100%;
}

.row {
    --bs-gutter-x: 0;
}

.col{
    flex: 0;
}

.form-check-input{
    width: 1.5rem !important;
    height: 1.5rem !important;
}

.form-check-input:checked {
    background-color: #bf7e3b;
    border-color: #bf7e3b;;
}

@media screen and (min-width:375px) {
    .accordion-button {
        height: 28vh;
    }
}

@media screen and (min-width:414px) {
    .accordion-button {
        height: 30vh;
    }
}

@media screen and (min-width:568px) {
    .accordion-button {
        height: 70vh;
        background-size: cover;
    }
}

@media screen and (min-width: 600px) {
    .Music_cardWrapper__rC8is {
        background-size: cover;
        background-position: center bottom -299px;
    }
    .accordion-button {
        height: 45vh;
        background-size: cover;
    }
}

@media screen and (min-width: 667px) {
    .accordion-button {
        height: 70vh;
        background-size: cover;
    }
}

@media screen and (min-width:768px) {
    .Music_cardBody__JCoib {
        padding: 1.8rem !important;
    }
}