@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');

.concertWrapper {
    padding:.5rem;
}

.concertTitle {
    font-size: 1.8rem;
    text-align: center;
    color: white;
    padding:1rem 0;
    font-family: "Rock Salt";
}

.backBtn,
.backBtnView,
.backBtnBook {
    background-color: #bf7e3b;
    border: 1px solid #bf7e3b;
}

.backBtn:hover,
.backBtnView:hover,
.backBtnBook:hover {
    background-color: #dcaa78;
    border: 1px outset white;
}

.backBtnBook {
    margin-left:1rem;
    background-color: #158352;
    border: 1px solid #158352;
}

.backBtnBook:hover {
    background-color: #3cb47e;
    border: 1px solid #c0e0d2;
}

/* for view-more */
.btnWrapper{
    display: flex;
}