@import url(https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@400;500;600&amp;display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat&amp;display=swap);

.logoStyle {
    width: 5rem;
    margin: 0 auto;
    padding-bottom: 1rem;
}

.thankYou {
    margin: 0;
    /* font-size: 46px; */
    font-size: 1.2rem;
    line-height: 1.4rem;
    font-weight: 600;
    font-family: "Inknut Antiqua", Helvetica, Arial, sans-serif;
    color: #bf7e3b;
    text-align: center;
}

.subText {
    font-size: 13px;
    color: rgb(183, 181, 181);
    line-height: 1rem;
    margin-top: .8rem;
    text-align: center;
    font-style: italic;
    margin-bottom: 3rem;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.textarea {
    background-color: rgb(255, 255, 255);
    margin-bottom: 1rem;
}

.label,
.radio {
    line-height: 1rem;
    margin-top: 1rem;
    font-family: Montserrat, Helvetica, Arial, sans-serif;
}

.radio {
    margin-top: 0;
    color: white;
    margin-bottom: 1rem;
    display: flex;
    width: 30vw;
    font-size: smaller;
}

.wrapperStyle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    background: url("../images/form-background.jpg") no-repeat;
    background-size: contain;
}

.formFiledStyle {
    background-color: rgba(0, 0, 0, 0.214) !important;
    border-radius: .5rem;
    border: 1px solid white;
    color: #bf7e3b;
    padding: .5rem;
    margin-top: -.5rem;
    text-align: left;
}

.btn {
    margin-top: 1rem;
    background-color: #bf7e3b;
    border: 1px solid #bf7e3b;
    width: 100%;
}

.btn:hover {
    background-color: #dcaa78;
    border: 1px outset white;
}


.datePickerPhoto {
    position: relative;
    flex: 1 1 auto;
    width:90vw;
    padding: .375rem .75rem;
    border: 1px solid #ced4da;
    border-radius: .375rem;
}

@media only screen and (min-width: 375px) {
    .thankYou {
        font-size: 1.4rem;
        line-height: 1.6rem;
    }

    .subText {
        font-size: 1rem;
        line-height: 1.2rem;
    }
}

@media only screen and (min-width: 414px) {
    .thankYou {
        font-size: 1.6rem;
        line-height: 1.8rem;
    }
}

@media only screen and (min-width: 568px) {
    .thankYou {
        font-size: 2.2rem;
        line-height: 2.6rem;
    }
}

@media only screen and (min-width: 600px) {
    .thankYou {
        font-size: 2.4rem;
        line-height: 2.7rem;
    }

    .logoStyle {
        width: 8rem;
    }

    .subText {
        font-size: 1.2rem;
        line-height: 1.2rem;
        padding: 0 1rem;
    }

    .btn {
        width: 20%;
    }
}

@media only screen and (min-width: 600px) {
    .subText {
        line-height: 1.5rem;
    }
}

@media only screen and (min-width: 768px) {
    .thankYou {
        font-size: 3rem;
        line-height: 3.5rem;
    }

    .formFiledStyle {
        width: 45rem;
    }

    .subText {
        font-size: rem;
        line-height: 1.2rem;
        padding: 0 1rem;
    }
    .datePickerPhoto {
        width:100%;
    }
    .radio {
        width: 20vw;
    }
}

@media only screen and (min-width: 800px) {

    .subText {
        line-height: 1.5rem;
    }
}

@media only screen and (min-width: 1024px) {
    .thankYou {
        font-size: 4rem;
        line-height: 4rem;
    }

    .formFiledStyle {
        width: 61rem;
    }

    .subText {
        font-size: 1.5rem;
        line-height: 1.7rem;
        padding: 0 6rem;
    }
    .radio {
        width: 18vw;
    }

}

@media only screen and (min-width: 1366px) {
    .thankYou {
        font-size: 4rem;
        line-height: 4rem;
        padding: 0 10rem;
    }

    .subText {
        padding: 0 10em;
        margin-bottom: 3rem;
    }
}

@media only screen and (min-width: 1920px) {
    .thankYou {
        font-size: 4rem;
        line-height: 4rem;
        padding: 0 30rem;
    }

    .subText {
        padding: 0 30rem;
    }
}