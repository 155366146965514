@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');

.title {
  margin: 2rem 0 0 0;
  font-size: 2rem !important;
  font-weight: bold;
  color: #bf7e3b;
  font-family: "Rock Salt";
}

.cardWrapper {
  text-align: center;
  border-radius: 0;
  padding: 1rem 0 2rem 0;
}

.AccordionBody {
  background: linear-gradient(153deg,
      rgb(4 26 57) 9%,
      rgb(0, 0, 0) 32%,
      rgb(4 26 57) 75%);
}

.photoBtn,
.photoBtnCelebrate,
.photoBtnFood,
.photoBtnChurch,
.photoBtnSports,
.photoBtnMaternity,
.photoBtnPeople,
.photoBtnEthan,
.photoBtnViewMore {
  width: 14rem;
  display: flex;
  margin: 0 auto;
  height: 11rem;
  background: url("../images/concert-btn-cover2.png") center no-repeat;
  background-size: cover;
  text-shadow: black 1px 1px 4px;
  font-size: 1.5rem;
  /* border: #ffffff7a 2px outset !important; */
}

.photoBtnCelebrate {
  background: url("../images/celebrate/cover-btn-celebrate.png") center no-repeat;
  background-size: cover;
}

.photoBtnFood {
  background: url("../images/food/cover-btn-food.png") center no-repeat;
  background-size: cover;
}

.photoBtnChurch {
  background: url("../images/church/cover-btn-church.png") center no-repeat;
  background-size: cover;
}

.photoBtnSports {
  background: url("../images/sports/cover-btn-sports.png") center no-repeat;
  background-size: cover;
}

.photoBtnMaternity {
  background: url("../images/maternity/cover-btn-maternity.png") center no-repeat;
  background-size: cover;
}

.photoBtnPeople {
  background: url("../images/people/cover-btn-people.png") center no-repeat;
  background-size: cover;
}

.photoBtnEthan {
  background: url("../images/ethan/cover-btn-ethan.png") center no-repeat;
  background-size: cover;
}

.photoBtnViewMore {
  background: url("../images/view-more.png") center no-repeat;
  background-size: contain;
  width: 10rem;
  margin-top: -1rem;
}

.photoBtn:hover,
.photoBtnCelebrate:hover,
.photoBtnFood:hover,
.photoBtnChurch:hover,
.photoBtnSports:hover,
.photoBtnMaternity:hover,
.photoBtnPeople:hover,
.photoBtnEthan:hover {
  /* border: #ffffff 2px outset !important; */
  background-color: transparent;
}

.h3ConcertWrapper,
.h3CelebrateWrapper,
.h3MaternityWrapper {
  margin-top: 2rem;
  border-radius: 0.5rem;
  background-color: rgba(0, 0, 0, 0.332);
  border: 1px #ffffff69 outset;
}

.h3ConcertWrapper:hover,
.h3CelebrateWrapper:hover,
.h3MaternityWrapper:hover  {
  background-color: #000000;
  filter: grayscale(100%);
}

.h3ConcertWrapper h3,
.h3CelebrateWrapper h3,
.h3MaternityWrapper h3 {
  color: white;
  padding-top: 1rem;
  font-size: 1rem;
}
.h3MaternityWrapper  {
  margin-top: .1rem;
}

.body{
  background-color: #202124;
  height: 100vh;
}

@media screen and (min-width:375px) {

  .photoBtn,
  .photoBtnCelebrate,
  .photoBtnFood,
  .photoBtnChurch,
  .photoBtnSports,
  .photoBtnMaternity,
  .photoBtnPeople,
  .photoBtnEthan  {
    width: 17rem;
    height: 11rem;
    background-size: 100% 100%;
  }
  
  .photoBtnPeople,
  .photoBtnEthan  {
    background-size: 100% 90%;
  }
}

@media screen and (min-width: 414px) {

  .photoBtn,
  .photoBtnCelebrate,
  .photoBtnFood,
  .photoBtnChurch,
  .photoBtnSports,
  .photoBtnMaternity,
  .photoBtnPeople,
  .photoBtnEthan   {
    width: 19rem;
    height: 13rem;
  }
  .photoBtnEthan  {
    background-size: 100% 86%;
  }
}

@media screen and (min-width:600px) {

  .photoBtn,
  .photoBtnCelebrate,
  .photoBtnFood,
  .photoBtnChurch,
  .photoBtnSports,
  .photoBtnMaternity,
  .photoBtnPeople,
  .photoBtnEthan  {
    width: 28rem;
    height: 18rem;
    background-size: 100% 100%;
  }

  .photoBtnPeople,
  .photoBtnEthan  {
    background-size: 100% 90%;
  }
}

@media screen and (min-width:768px) {
  .cardBody {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .h3ConcertWrapper,
  .h3CelebrateWrapper {
    width: 13rem;
    margin: .5rem;
    /* margin-right: 1rem; */
  }

  .photoBtn,
  .photoBtnCelebrate,
  .photoBtnFood,
  .photoBtnChurch,
  .photoBtnSports,
  .photoBtnMaternity,
  .photoBtnPeople,
  .photoBtnEthan   {
    width: 13rem;
    height: 8rem;
    background-size: 100% 100%;
  }

  .photoBtnPeople,
  .photoBtnEthan  {
    background-size: 100% 95%;
  }
}

@media screen and (min-width:1024px) {
  .cardBody {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
  }

  .h3ConcertWrapper,
  .h3CelebrateWrapper {
    width: 16rem;
    margin: .5rem;
    /* margin-right: 1rem; */
  }

  .photoBtn,
  .photoBtnCelebrate,
  .photoBtnFood,
  .photoBtnChurch,
  .photoBtnSports,
  .photoBtnMaternity,
  .photoBtnPeople,
  .photoBtnEthan   {
    width: 15rem;
    height: 10rem;
    background-size: 100% 100%;
  }

  .photoBtnPeople,
  .photoBtnEthan  {
    background-size: 100% 92%;
  }
}